import { StatisticDataInit } from '../../../../../api/services/dtStatistics/model/dtStatistics.model';
import { findBy } from '../../../../../helpers/findBy';
import { getObjectEntries } from '../../../../../helpers/getObjectEntries';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { ChartBDKeys } from '../../../../InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import { DataValue } from '../../models/chart.model';
import { GRAPH_DICT, Y_STATISTIC_KEY } from '../constants/constants';

import getTranslateKey from './getTranslateKey';

const getTranslateData = (data: Nullish<DataValue>) => {
  if (!data) return null;

  const formatted = getObjectEntries(data).reduce(
    (acc: DataValue, [key, value]) => {
      const translatedWord = getTranslateKey(key);

      acc[translatedWord] = value;

      return acc;
    },
    {}
  );

  return formatted;
};

const timePrepared = (dateFrom: string, dateTo: string, timezone: number) =>
  `${dateFrom} - ${dateTo} (UTC +${timezone})`;

const getPreparedGraphStructure = (
  data: Nullish<StatisticDataInit>,
  type: ChartBDKeys
) => {
  const {
    filterDtData: { directions },
  } = rootStore.detailedStore;

  if (!directions || !data) return { formattedData: [], chartNames: null };

  const formattedData = data.data.reduce((acc: DataValue[], item) => {
    const { date_from, date_to, info } = item;

    const time = timePrepared(date_from, date_to, data.timezone);

    const currentInfo = findBy(info, directions[0], 'lb_direction_id');

    if (currentInfo) {
      const formattedData = getTranslateData(currentInfo[GRAPH_DICT[type]]);

      acc.push({
        [Y_STATISTIC_KEY]: time,
        ...formattedData,
      });
    }

    return acc;
  }, []);

  const chartNames = getTranslateData(formattedData?.at(0));

  return { formattedData, chartNames };
};

export default getPreparedGraphStructure;
