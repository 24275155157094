import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import {
  DEFAULT_PERIOD_VALUES,
  FilteredTypes,
  GraphTypes,
} from '../../TrafficLightDetailed/TlDetectors/constants/constants';
import { getTimeWithTimeZone } from '../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';

import ButtonsPanel, { IButtonsPanelProps } from './ButtonsPanel/ButtonsPanel';
import FilterButtons from './ButtonsPanel/FilterButtons/FilterButtons';
import CandleChart from './CandleChart/CandleChart';
import { INITIAL_FILTERED, INITIAL_UPDATE_INFO } from './constants/constants';
import { getCalcData } from './helpers/getCalcData';
import useScaleGraph from './hooks/useScaleGraph';
import LineChart from './LineChart/LineChart';
import { ChartProps, UpdateInfo } from './models/chart.model';

import styles from './Chart.module.scss';

const Chart: FC<ChartProps> = ({
  title,
  id,
  data,
  colors,
  verticalLabel,
  horizontalLabel,
  className = '',
  isAvgValue = false,
  isLoading = false,
  isError = false,
  filterInfo = [],
  graphInfo,
  radioProps,
  handleUpdateGraph,
}) => {
  const { graphType, setFilteredDtData } = rootStore.detailedStore;

  const [timeUpdate, setTimeUpdate] = useState<UpdateInfo>(INITIAL_UPDATE_INFO);
  const [isUpdate, setIsUpdate] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const { dateFormatted } = getTimeWithTimeZone();

    setTimeUpdate((prev) => ({ ...prev, LineChart: dateFormatted }));
  }, [isLoading]);

  useEffect(() => {
    setFilteredDtData({
      filteredType: FilteredTypes.Directions,
      value: INITIAL_FILTERED[graphType].directions,
    });
  }, [id, graphType, setFilteredDtData]);

  const calcData = useMemo(
    () =>
      getCalcData({
        data,
        isAvgValue,
        keyX: horizontalLabel.key,
        keyY: verticalLabel.key,
      }),
    [isAvgValue, data, horizontalLabel.key, verticalLabel.key]
  );

  const {
    areaX,
    domain,
    handleMouseDown,
    handleMouseMove,
    zoom,
    handleZoomOut,
  } = useScaleGraph({
    data: calcData,
  });

  const isLineChart = graphType === GraphTypes.LineChart;

  const changePeriod = (key: string) => {
    if (isLineChart) return radioProps.changeDate(key);

    radioProps.setDateInfo((prev) =>
      prev ? { ...prev, radioValueDate: key } : null
    );
  };

  const containerStyle = classNames({
    [styles.container]: true,
    [className]: className,
  });

  useEffect(() => {
    changePeriod(DEFAULT_PERIOD_VALUES[graphType]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphType]);

  const buttonsPanelProps: IButtonsPanelProps = {
    domain,
    data: calcData,
    timeUpdate,
    isLoading,
    isError,
    handleZoomOut,
    radioProps: {
      ...radioProps,
      changeDate: changePeriod,
    },
    title,
    graphInfo,
    handleUpdateGraph: isLineChart
      ? handleUpdateGraph
      : () => {
          setIsUpdate((prev) => !prev);
        },
    buttonsConfigure: {
      isExport: isLineChart,
      isScale: isLineChart,
    },
  };

  return (
    <div ref={containerRef} className={containerStyle}>
      <FilterButtons filterInfo={filterInfo} isLoading={isLoading} />
      {isLineChart ? (
        <LineChart
          data={data}
          interval={graphInfo.interval}
          horizontalLabel={horizontalLabel}
          isAvgValue={isAvgValue}
          isError={isError}
          isLoading={isLoading}
          verticalLabel={verticalLabel}
          colors={colors}
          chartHelpers={{
            areaX,
            calcData,
            domain,
            handleMouseDown,
            handleMouseMove,
            zoom,
          }}
        />
      ) : (
        <CandleChart
          valueType={radioProps.graphValues.graphTitle}
          yAxisName={verticalLabel.value}
          id={id}
          isUpdate={isUpdate}
          predictInterval={radioProps.radioValueDate}
          setTimeUpdate={setTimeUpdate}
        />
      )}
      <ButtonsPanel {...buttonsPanelProps} />
    </div>
  );
};

export default observer(Chart);
