import { useState } from 'react';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';
import { useDebouncedCallback, type Options } from 'use-debounce';

import { DEBOUNCE_TIME } from '../../../constants/debounceConstants';
import { OFFSET_STEP } from '../Offset';
import { OffsetKey, OffsetProps } from '../Offset.model';

const { X, Y } = OffsetKey;
const { FASTEST } = DEBOUNCE_TIME;
const OPTIONS: Options = { maxWait: FASTEST };

const useOffset = ({
  offsetX,
  offsetY,
  onChange,
  id = -1,
  rate = 2,
  step = OFFSET_STEP,
}: OffsetProps) => {
  const [xOffset, setXOffset] = useState(offsetX);
  const [yOffset, setYOffset] = useState(offsetY);

  const range = rate * 100;

  const handleChangeX = useDebouncedCallback(onChange, FASTEST, OPTIONS);
  const handleChangeY = useDebouncedCallback(onChange, FASTEST, OPTIONS);

  const handleOffsetX = (val: number) => {
    handleChangeX(X, val, id);
    setXOffset(val);
  };

  const handleOffsetY = (val: number) => {
    handleChangeY(Y, -val, id);
    setYOffset(-val);
  };

  const getOffset = (val: N<number>) =>
    Math.round(((val ?? 0) * range) / step) * step;

  const handleJoystick = (e: IJoystickUpdateEvent) => {
    const xOffset = getOffset(e.x);
    const yOffset = getOffset(e.y);

    handleOffsetX(xOffset);
    handleOffsetY(yOffset);
  };

  return {
    range,
    xOffset,
    yOffset,
    handleOffsetX,
    handleOffsetY,
    handleJoystick,
  };
};

export default useOffset;
