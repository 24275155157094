import { LibraryValues } from '../../uiStore/uiStore.model';

export const STATISTIC_TEST_DICT: LibraryValues[] = [
  {
    id: 1,
    name: 'минимальное значение',
    dsc: 'минимальное значение',
    mnemocode: 'min',
  },
  {
    id: 2,
    name: 'нижний квартиль',
    dsc: '75% значений выше этого уровня',
    mnemocode: 'q1',
  },
  {
    id: 3,
    name: 'медиана',
    dsc: 'середина набора значений',
    mnemocode: 'med',
  },
  {
    id: 4,
    name: 'верхний квартиль',
    dsc: '75% значений ниже этого уровня',
    mnemocode: 'q3',
  },
  {
    id: 5,
    name: 'максимальное значение',
    dsc: 'максимальное значение',
    mnemocode: 'max',
  },
  {
    id: 6,
    name: 'среднее арифметическое',
    dsc: 'среднее арифметическое',
    mnemocode: 'avg',
  },
  {
    id: 7,
    name: 'значение',
    dsc: 'измеренное или прогнозируемое значение',
    mnemocode: 'value',
  },
];
