import type { Payload } from 'recharts/types/component/DefaultLegendContent';

import { CalcData } from '../models/chart.model';

import getCorrectText from './helpers/getCorrectText';

import styles from './CustomedTick.module.scss';

let lastIndex = 0;

interface CustomedTickProps {
  x: number;
  y: number;
  payload: Payload;
  isX: boolean;
  data?: CalcData[];
  labelKey: string;
  visibleTicksCount: number;
  index: number;
}

const CustomedTick = ({
  x,
  y,
  payload,
  isX,
  data,
  labelKey,
  visibleTicksCount,
  index,
}: CustomedTickProps) => {
  if (isX) {
    const indexPayload = payload.value;
    const isLastValue = data && indexPayload === data.length - 1;
    const value = data?.[indexPayload]?.[labelKey]?.toString();
    const valueLast = data?.[lastIndex]?.[labelKey]?.toString();

    const valuesArray = value?.split(' ');
    const lastValue = valueLast?.split(' ')?.at(0);

    const lastDate = valuesArray?.at(0);
    const time = valuesArray?.at(1);

    const isDifDates = lastDate && lastValue && lastDate !== lastValue;

    lastIndex = indexPayload;

    const textAnchor = !isLastValue ? 'start' : 'end';

    return (
      <g transform={`translate(${x},${y})`} className={styles.textLabel}>
        <text x={0} y={0} dy={16} textAnchor={textAnchor}>
          {time}
        </text>
        {isDifDates && (
          <text x={0} y={20} dy={16} textAnchor={textAnchor}>
            {getCorrectText(lastDate)}
          </text>
        )}
      </g>
    );
  }

  const isLast = visibleTicksCount - 1 === index;

  if (!payload.value || isLast) return null;

  return (
    <g transform={`translate(${x},${y})`} className={styles.textLabel}>
      <text x={-5} y={0} dy={16} textAnchor="end">
        {payload.value}
      </text>
    </g>
  );
};

export default CustomedTick;
