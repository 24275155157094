import { StatisticPoint } from '../../../../../api/services/dtStatistics/model/dtStatistics.model';
import { SECOND } from '../../../../../constants/constants';
import { ChartBDKeys } from '../../../../InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import { PREDICT_PERIOD_VALUES } from '../../../../TrafficLightDetailed/TlDetectors/constants/constants';

const { PassageCount, SpeedAvg } = ChartBDKeys;

type StatisticPointKeys = keyof Omit<
  StatisticPoint['info'][number],
  'lb_direction_id'
>;

type GraphDict = Record<ChartBDKeys, StatisticPointKeys>;

export const GRAPH_DICT: GraphDict = {
  [PassageCount]: 'volume',
  [SpeedAvg]: 'speed',
};

export const Y_STATISTIC_KEY = 'time';

export const DISABLED_BTN_TEXT = 'Недоступно в данном графике';

export const VALUE_KEY = 'value';

export const DEFAULT_RANGE = PREDICT_PERIOD_VALUES.HOUR / SECOND;
