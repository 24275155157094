import { cloneDeep, isNumber } from 'lodash';
import { makeAutoObservable } from 'mobx';

import {
  ALL,
  DEFAULT,
  DEFAULT_FILTERED,
  FilteredTypes,
  GraphTypes,
} from '../../components/TrafficLightDetailed/TlDetectors/constants/constants';
import { IDateInfo } from '../../components/TrafficLightDetailed/TlDetectors/model/graph.model';
import { DISABLED_CHART_BUTTONS } from '../../components/ui-kit/Chart/constants/constants';
import { TlManagementKeys } from '../../constants/commands';
import { findById } from '../../helpers/findBy';
import RootStore from '../rootStore/rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';
import { LibraryValues } from '../uiStore/uiStore.model';

import {
  HandleSetFilterProps,
  MapPropsDetailed,
  SetIsNotDetailed,
  SetMapPropsDetailed,
} from './detailedStore.model';
import { getFilteredValue } from './helpers/filteredHelpers/getFilteredValue';
import { getParentFilterValue } from './helpers/filteredHelpers/getParentFilterValue';
import { STATISTIC_TEST_DICT } from './testData/testData';

export type DetailedStoreType = DetailedStore;

class DetailedStore {
  rootStore;
  selectedPhase: N<number> = null;
  generalDtPeriodInfo: N<IDateInfo> = null;
  activeDtId: N<number> = null;
  selectPlanNumber: N<number> = null;
  filterDtData = cloneDeep(DEFAULT_FILTERED);
  stopTimerTrigger = false;
  mapPropsDetailed: N<MapPropsDetailed> = null;
  isMapLoading = true;
  isActiveControlBtn = false;
  activeControlKey = TlManagementKeys.Management;
  isDisabledControlCmd = true;
  predictDict: N<LibraryValues[]> = STATISTIC_TEST_DICT;
  graphType = GraphTypes.LineChart;
  setDetailedValue;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;

    const utils = new StoreUtils(this);

    this.setDetailedValue = utils.setKeyValue;
  }

  setIsNotDetailed: SetIsNotDetailed = (key, bool) => {
    this[key] = bool !== undefined ? bool : !this[key];
  };

  setMapPropsDetailed: SetMapPropsDetailed = (
    currZoom: number,
    resolution: number
  ) => {
    this.mapPropsDetailed = { currZoom, resolution };
  };

  setSelectPlanNumber = (planNumber: N<number>) => {
    this.selectPlanNumber = planNumber;
  };

  setGeneralDtInfo = (dateInfo: N<IDateInfo>) =>
    (this.generalDtPeriodInfo = dateInfo ? { ...dateInfo } : null);

  setActiveDtId = (id: N<number>) => (this.activeDtId = id);

  setSelectedPhase = (phase: N<number>) => {
    const isSelectedPhase = this.selectedPhase === phase;

    if (isSelectedPhase || phase === null) {
      this.selectedPhase = null;

      return;
    }
    this.selectedPhase = phase;
  };

  setFilteredDtData = ({
    filteredType,
    value,
    parentFilter,
    parentType,
    isOnceClick = false,
  }: HandleSetFilterProps) => {
    const filteredData = this.filterDtData[filteredType];

    if (value === DEFAULT)
      return (this.filterDtData = cloneDeep(DEFAULT_FILTERED));

    if (Array.isArray(value)) {
      return (this.filterDtData[filteredType] = value);
    }

    if (isOnceClick && isNumber(value)) {
      return (this.filterDtData[filteredType] = [value]);
    }

    if (parentFilter && parentType) {
      const parentDataFilter = this.filterDtData[parentFilter];

      const isNeedOffOtherFilters =
        !!value && value !== ALL && parentDataFilter?.length && !filteredData;

      const isNeedAddFilter = Boolean(
        value &&
          parentDataFilter?.every((el) => el !== parentType) &&
          filteredData
      );

      const childType =
        isNeedOffOtherFilters || isNeedAddFilter
          ? [value]
          : getFilteredValue(filteredData, value);

      const parentData = getParentFilterValue(
        parentDataFilter,
        value,
        parentType,
        filteredData
      );

      this.filterDtData = {
        ...this.filterDtData,
        [parentFilter]:
          parentData === undefined
            ? this.filterDtData[parentFilter]
            : parentData,
        [filteredType]: childType,
      };

      return;
    }

    const isOffLanes =
      !!this.filterDtData[FilteredTypes.Directions] &&
      !!this.filterDtData[FilteredTypes.Lanes] &&
      filteredType === FilteredTypes.Directions &&
      value !== ALL &&
      !!value;

    const isNeedOff = !!filteredData?.some((el) => el === value);
    const isNeedNullable = isNeedOff && filteredData?.length === 1;

    if (isOffLanes) {
      const dirValues = isNeedOff
        ? filteredData?.filter((el) => el !== value) ?? null
        : [...(filteredData ?? []), value];

      return (this.filterDtData = {
        ...this.filterDtData,
        [FilteredTypes.Lanes]: null,
        [FilteredTypes.Directions]: isNeedNullable ? null : dirValues,
      });
    }

    const isOffDirs = !this.filterDtData[FilteredTypes.Directions];

    const isNeedOnDirs =
      isOffDirs &&
      filteredType !== FilteredTypes.Directions &&
      filteredType !== FilteredTypes.Lanes;

    isNeedOnDirs &&
      (this.filterDtData[FilteredTypes.Directions] = cloneDeep(
        DEFAULT_FILTERED.directions
      ));

    this.filterDtData[filteredType] = getFilteredValue(filteredData, value);
  };

  setStopTimerTrigger = () => {
    this.stopTimerTrigger = !this.stopTimerTrigger;
  };

  clearControlTab = () => {
    const { setIsNotDetailed, setDetailedValue } = this;

    setIsNotDetailed('isActiveControlBtn', false);
    setIsNotDetailed('isDisabledControlCmd', true);
    setDetailedValue('activeControlKey', TlManagementKeys.Management);
  };

  get activeTLPlanNumber() {
    const { infoData } = this.rootStore.uiStore;
    const { tls } = this.rootStore.mapDataStore;

    return findById(tls, infoData?.id)?.tlGeneral?.progNum ?? null;
  }

  get disabledChartTypes() {
    return DISABLED_CHART_BUTTONS[this.graphType];
  }
}

export default DetailedStore;
