import { Id } from '../../../../ts/models/constructor.model';

export enum OffsetKey {
  X = 'offsetX',
  Y = 'offsetY',
}

export type OnChange = (key: OffsetKey, val: number, id: Id) => void;

export interface OffsetProps {
  onChange: OnChange;
  id?: Id;
  offsetX: number;
  offsetY: number;
  rate?: 1 | 2;
  isVisible?: boolean;
  label?: string;
  step?: number;
}
