/* eslint-disable camelcase */
import { z } from 'zod';

import { NNInt } from '../../../zod/utils.zod';

export type StatisticAdapter = z.infer<typeof StatisticAdapterSchema>;

export enum EnabledStatus {
  Off,
  On,
}

const AdapterScheme = z.object({
  host_id: z.string(),
  uptime_sec: z.number(),
  session_uuid: z.string().uuid(),
  status: z.string(),
  lastTimeTZ: z.string(),
  statistics: z
    .object({
      received_passes_from_start: z.number().nullish(),
      received_passes_from_last_10min: z.number().nullish(),
      events_from_start: z.number().nullish(),
      events_from_last_10min: z.number().nullish(),
    })
    .nullish(),
  error: z
    .object({
      jerror: z.record(z.any()),
      timeTZ: z.string(),
    })
    .nullable(),
});

const AdapterAddInfoScheme = z.object({
  adapter: AdapterScheme.nullish(),
});

export const StatisticAdapterSchema = z.object({
  sc_system_id: z.number(),
  sc_system_type_id: z.number(),
  sub_systems_id: z.number().array(),
  add_info: AdapterAddInfoScheme,
  sc_system_regions_id: NNInt.array().nullable(),
  is_enabled: z.nativeEnum(EnabledStatus),
});
