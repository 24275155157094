import { List, Tag } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import {
  DEVICES_INFO,
  DEVICES_STATE,
} from '../../../../../../../constants/devicesConstants';
import { findBy } from '../../../../../../../helpers/findBy';
import { HandleScrollType } from '../../../../../../../hooks/useScrollToElement';
import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { IDataList } from '../../../../model/monitoring.model';

import Description from './Description/Description';
import DeviceAvatar from './DeviceAvatar/DeviceAvatar';
import Title from './Title/Title';

import styles from './DeviceItem.module.scss';

interface DeviceItemProps extends IDataList {
  addressLoading: boolean;
  addToRefs?: (el: N<HTMLElement>, id: number) => void;
  handleScroll?: HandleScrollType;
  isClickable?: boolean;
}

const DeviceItem: FC<DeviceItemProps> = ({
  avatar,
  id,
  status,
  system,
  title,
  address,
  addressLoading,
  isClickable = true,
  addToRefs,
  handleScroll,
}) => {
  const { deviceInfo, setMonitoringValue } = rootStore.monitoringPanelStore;

  const isActive = deviceInfo?.id === id;

  const state =
    findBy(DEVICES_STATE, status, 'com') ?? DEVICES_INFO.STATE.Undefined;

  const handleClick = () =>
    isClickable &&
    !isActive &&
    setMonitoringValue('deviceInfo', { id, system });

  useEffect(() => {
    const isCurrentElement = deviceInfo?.id === id;

    if (!isCurrentElement || !handleScroll) return;

    let timeoutId: NodeJS.Timeout;

    const getTimeoutId = (timeout: NodeJS.Timeout) => {
      timeoutId = timeout;
    };

    handleScroll(id, getTimeoutId);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [deviceInfo?.id, id, handleScroll]);

  const itemStyle = classNames(styles.item, {
    [styles.clickable]: isClickable,
    [styles.active]: isActive,
  });

  return (
    <List.Item
      key={id}
      className={itemStyle}
      onClick={handleClick}
      id={id.toString()}
      ref={(el) => {
        addToRefs?.(el, id);
      }}
    >
      <List.Item.Meta
        avatar={
          <DeviceAvatar
            id={id}
            status={status}
            iconSystem={avatar}
            isActive={isActive}
          />
        }
        title={<Title title={title} />}
        description={
          <Description
            id={id}
            system={system}
            isActive={isActive}
            address={address}
            addressLoading={addressLoading}
          />
        }
      />
      <Tag color={state.color}>{state.dsc}</Tag>
    </List.Item>
  );
};

export default observer(DeviceItem);
