import { Input } from 'antd';
import React, { useState } from 'react';
import { TiLocation } from 'react-icons/ti';

import { geomToLatLon } from '../../../../apiGIS/geometry/helpers';
import { geocoderSearch } from '../../../../apiGIS/helpers/search';
import {
  getMapPoint,
  IDrawResult,
} from '../../../../apiGIS/tasks/interactions';
import { TMap } from '../../../../stores/mapStore/mapStore.model';
import ButtonOnlyIcon from '../../../ui-kit/ButtonOnlyIcon/ButtonOnlyIcon';
import { START_POINT_ICON_SIZE, START_POINT_STYLE } from '../constants';
import { IRoutePoint } from '../route.model';

import styles from '../RouteButton.module.scss';

const { Search } = Input;

export const RoutePoint = (props: {
  map: TMap;
  value: IRoutePoint;
  onChange: (evt: IRoutePoint) => void;
}) => {
  const [selection, setSelection] = useState<boolean>(false);

  const getPoint = async () => {
    setSelection(true);

    const action: IDrawResult = await getMapPoint(props.map);

    const { feature } = action;

    if (!feature) {
      return;
    }

    const lonLat = geomToLatLon(feature.getGeometry());

    if (!lonLat) {
      setSelection(false);

      return;
    }

    const [x, y] = lonLat;

    const searchItems = await geocoderSearch(props.map, `${x},${y}`, undefined);

    const title =
      searchItems && searchItems.length > 0
        ? searchItems[0].title
        : `${x},${y}`;

    const item: IRoutePoint = {
      feature,
      title,
      selected: true,
      x,
      y,
    };

    props.onChange(item);

    setSelection(false);
  };

  return (
    <Search
      value={props.value.title}
      placeholder="Точка на карте"
      className={styles.routeField}
      addonBefore={
        <div className={styles.filler}>
          <ButtonOnlyIcon onClick={getPoint} style={START_POINT_STYLE}>
            <TiLocation
              size={START_POINT_ICON_SIZE}
              className={selection ? styles.selectionActive : ''}
            />
          </ButtonOnlyIcon>
        </div>
      }
    />
  );
};
