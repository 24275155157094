import { Dropdown } from 'antd';
import classNames from 'classnames';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { CSSProperties, FC, RefObject } from 'react';

import { ReactComponent as StreamIco } from '../../../../assets/icons/buttons/ic_stream.svg';
import { findById } from '../../../../helpers/findBy';
import { getPopupContainer } from '../../../ui-kit/Popover/helpers/getPopupContainer/getPopupContainer';
import Popover, { PopoverProps } from '../../../ui-kit/Popover/Popover';
import { getPreparedActiveStreamUrl } from '../../helpers/getPreparedActiveStreamUrl/getPreparedActiveStreamUrl';
import {
  ActiveStream,
  VideoSourcesCamera,
} from '../../models/VideoCamera.model';

import { getStreamItems } from './helpers/getStreamItems/getStreamItems';

import styles from './ThreadsSelect.module.scss';

export interface TreadsSelectProps {
  activeStream: N<ActiveStream>;
  setActiveStream: SetState<N<ActiveStream>>;
  videoSources?: VideoSourcesCamera[];
  containerRef?: RefObject<HTMLElement>;
  popoverProps: Omit<PopoverProps, 'children' | 'content'>;
  basicProps?: {
    className: string;
    style: CSSProperties;
  };
}

const ThreadsSelect: FC<TreadsSelectProps> = ({
  activeStream,
  setActiveStream,
  videoSources = [],
  containerRef,
  popoverProps,
  basicProps,
}) => {
  const onClick: MenuClickEventHandler = ({ key }) => {
    const videoItem = findById(videoSources, key) as VideoSourcesCamera;

    const activeStreamUrl = getPreparedActiveStreamUrl(videoItem.url);

    setActiveStream({ ...videoItem, url: activeStreamUrl });
  };

  const isDisabled = videoSources.length <= 1 || !activeStream;

  const streamsStyle = classNames(basicProps?.className, {
    [styles.disabled]: isDisabled,
  });

  const icon = (
    <StreamIco
      className={streamsStyle}
      style={basicProps?.style}
      data-testid="icon"
    />
  );

  if (isDisabled)
    return (
      <Popover {...popoverProps} content="В данной камере единственный поток">
        {icon}
      </Popover>
    );

  const popupContainer = getPopupContainer(containerRef)?.func;

  return (
    <Dropdown
      menu={{
        items: getStreamItems(videoSources, activeStream, styles.active),
        onClick,
      }}
      placement="top"
      getPopupContainer={popupContainer}
      data-testid="dropdown"
    >
      {icon}
    </Dropdown>
  );
};

export default ThreadsSelect;
