import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { SECOND } from '../../../../../../constants/constants';
import { UPDATE_PREDICT_CHART_INTERVAL } from '../../../../../../constants/queryConstants';
import rootStore from '../../../../../../stores/rootStore/rootStore';
import { GraphTypes } from '../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import { getIntervalFormatted } from '../../../helpers/getIntervalFormatted';
import { InfoButtonProps } from '../InfoButton';

import getSelectedPeriod from './helpers/getSelectedPeriod';

const PopoverInfoContent: FC<InfoButtonProps> = ({ graphInfo, timeUpdate }) => {
  const { graphType } = rootStore.detailedStore;

  const isCandleChart = graphType === GraphTypes.CandlesChart;

  const dates = getSelectedPeriod({ ...graphInfo, isCandleChart });

  const intervalText = getIntervalFormatted(
    isCandleChart ? UPDATE_PREDICT_CHART_INTERVAL / SECOND : graphInfo.interval
  );

  const correctTimeUpdate = timeUpdate[graphType];

  return (
    <div>
      {correctTimeUpdate && <p>Обновлено: {correctTimeUpdate}</p>}{' '}
      <p>Выбранный период: {dates}</p>
      <p>Выбранный интервал: {intervalText}</p>
    </div>
  );
};

export default observer(PopoverInfoContent);
