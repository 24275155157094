import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { DEFAULT_CIRCLE } from '../../../../constants/constructorConstants';
import checkIsTLValid from '../../../../helpers/checkIsTLValid';
import rootStore from '../../../../stores/rootStore/rootStore';
import LinkedDevices from '../LinkedDevices/LinkedDevices';
import PhaseCircle from '../PhaseCircle/PhaseCircle';
import PhaseCircleCrossroad from '../PhaseCircleCrossroad/PhaseCircleCrossroad';
import PhaseCircleHeader from '../PhaseCircleHeader/PhaseCircleHeader';
import PhaseCircleLayout from '../PhaseCircleLayout/PhaseCircleLayout';
import { PhaseCircleEmptyProps } from '../PhaseCircleOverlay.model';

import styles from './PhaseCircleEmpty.module.scss';

const PhaseCircleEmpty: FC<PhaseCircleEmptyProps> = ({
  selectedTL,
  color,
  isNoData = false,
  pointUds,
}) => {
  const { isCrossroadBorder } = rootStore.mapDataStore;

  const {
    dai_jdt83,
    tlStatus,
    id,
    deviceInfo: { externalDeviceId },
  } = selectedTL;

  const crossroad = dai_jdt83?.crossroad;
  const data = [{ name: 'onePhase', time: 100, color }];
  const cmd = tlStatus?.command?.full;
  const isTLValid = checkIsTLValid(selectedTL);

  return (
    <PhaseCircleLayout id={externalDeviceId} isTLValid={isTLValid}>
      <PhaseCircle
        timer={100}
        len={100}
        circleBorderWidth={DEFAULT_CIRCLE.BORDER_WIDTH}
        color={color}
        data={data}
      />

      {crossroad && isCrossroadBorder && (
        <PhaseCircleCrossroad tlCrossroad={crossroad} phaseIdx={-1} id={id} />
      )}

      {pointUds && <LinkedDevices pointUds={pointUds} />}

      {isNoData && !isCrossroadBorder && (
        <p className={styles.noData}>
          Недостаточно данных для отображения светофорных фаз
        </p>
      )}

      <PhaseCircleHeader color={color} cmd={cmd} />
    </PhaseCircleLayout>
  );
};

export default observer(PhaseCircleEmpty);
