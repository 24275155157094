import { findBy } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';

const getTranslateKey = (key: string) => {
  const { predictDict } = rootStore.detailedStore;

  const translateKey = findBy(predictDict ?? [], key, 'mnemocode')?.name ?? key;

  return translateKey;
};

export default getTranslateKey;
