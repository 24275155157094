import { RadioChangeEvent } from 'antd/lib';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { GRAPH_TYPES_VALUES } from '../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import RadioButtonsGraph from '../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/RadioButtonsGraph/RadioButtonsGraph';

import styles from './GraphTypesBtns.module.scss';

const GraphTypesBtns = () => {
  const { graphType, setDetailedValue } = rootStore.detailedStore;

  const onChangeGraphTitle = (e: RadioChangeEvent) => {
    setDetailedValue('graphType', e.target.value);
  };

  return (
    <RadioButtonsGraph
      radioContainerClassName={styles.radioContainer}
      btnsDscArr={GRAPH_TYPES_VALUES}
      value={graphType}
      onChange={onChangeGraphTitle}
    />
  );
};

export default observer(GraphTypesBtns);
