import { List } from 'antd';
import { FC } from 'react';

import DeviceItem from '../../../../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/DevicesBlock/DevicesList/DeviceItem/DeviceItem';
import { DisplayTypeProps } from '../constants/devicesDisplayTypes';

const ListDisplay: FC<DisplayTypeProps> = ({ addressLoading, dataList }) => {
  return (
    <List
      dataSource={dataList}
      renderItem={(item) => (
        <DeviceItem
          {...item}
          key={item.id}
          addressLoading={addressLoading}
          isClickable={false}
        />
      )}
    />
  );
};

export default ListDisplay;
