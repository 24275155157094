import { observer } from 'mobx-react-lite';
import { FC, useMemo, useState } from 'react';
import { MdDateRange } from 'react-icons/md';

import { findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';
import { ChartBDKeys } from '../../../InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import Chart from '../../../ui-kit/Chart/Chart';
import ModalBody from '../../TlManagement/PhaseHold/ModalBody/ModalBody';
import ModalConfirm from '../../TlManagement/PhaseHold/ModalConfirm/ModalConfirm';
import { INTERVALS_NAMES } from '../constants/constants';
import { IDtInfo, IGraphValues } from '../model/graph.model';

import useIntancyGraph from './hooks/useIntancyGraph';

import styles from './IntancyGraph.module.scss';

const initialDtInfo: IDtInfo = {
  dtId: 0,
  title: '',
};

const initialGraphValues: IGraphValues = {
  dtInfo: initialDtInfo,
  graphTitle: ChartBDKeys.PassageCount,
};

interface IIntancyGraphProps {
  dtId: N<number>;
}

const IntancyGraph: FC<IIntancyGraphProps> = ({ dtId }) => {
  const { detectors } = rootStore.mapDataStore;
  const { generalDtPeriodInfo, filterDtData } = rootStore.detailedStore;

  const [graphValues, setGraphValues] =
    useState<IGraphValues>(initialGraphValues);
  const [standardInterval, setStandardInterval] =
    useState<U<INTERVALS_NAMES>>();

  const { graphTitle } = graphValues;
  const isVehicles = graphTitle === ChartBDKeys.PassageCount;

  const titleGraph = `График ${
    graphTitle === ChartBDKeys.PassageCount
      ? 'интенсивности'
      : 'средней скорости'
  } движения`;

  const activeDetector = useMemo(
    () => findById(detectors, dtId),
    [detectors, dtId]
  );

  const {
    changeDate,
    modalConfirmProps,
    isLoading,
    isError,
    modalBodyProps,
    dataGraph,
    dateInfo,
    colors,
    filterInfo,
    setDateInfo,
    handleUpdateGraph,
  } = useIntancyGraph({
    graphTitle,
    activeDetector,
    standardInterval,
    generalDtPeriodInfo,
    filterDtData,
    setStandardInterval,
  });

  const radioProps = {
    changeDate,
    graphValues,
    setGraphValues,
    setDateInfo,
  };

  return (
    <div className={styles.containerGraph}>
      <div className={styles.titleBlock}>
        <h3>{titleGraph}</h3>
        {dataGraph?.title && <h4>{dataGraph.title}</h4>}
      </div>
      <div className={styles.containerChart}>
        {dateInfo ? (
          <Chart
            id={activeDetector?.id}
            data={dataGraph?.data ?? []}
            colors={colors}
            title={titleGraph}
            isAvgValue={!isVehicles}
            horizontalLabel={{
              key: 'time',
              value: '',
            }}
            verticalLabel={{
              key: graphTitle,
              value: isVehicles ? 'кол-во ТС' : 'скорость, км/ч',
            }}
            isLoading={isLoading}
            isError={isError}
            graphInfo={dateInfo}
            radioProps={{
              ...radioProps,
              radioValueDate: dateInfo.radioValueDate,
            }}
            filterInfo={filterInfo}
            handleUpdateGraph={handleUpdateGraph}
          />
        ) : null}
      </div>
      <ModalConfirm
        {...modalConfirmProps}
        icon={<MdDateRange className={styles.iconInfo} />}
      >
        <ModalBody {...modalBodyProps} />
      </ModalConfirm>
    </div>
  );
};

export default observer(IntancyGraph);
