import { useEffect, useMemo, useState } from 'react';

import mapObjects from '../../../../../api/mapObjects';
import { Detector } from '../../../../../ts/models/mapObject.model';
import {
  FilterDataValues,
  FilterInfo,
} from '../../../../ui-kit/Chart/models/chart.model';
import { DATES } from '../../constants/constants';
import {
  IDateInfo,
  isLineChartPeriod,
  RadioValue,
} from '../../model/graph.model';
import { getFilteredInfo } from '../helpers/getFilteredInfo';
import { getTimeZones } from '../TitleBtns/TimeLocalCard/helpers/getTimeZone';

const { SELECT_TIME } = DATES;

const { useDetectorsData } =
  mapObjects.detectors.detectorsData.detectorsDataApi;

interface UseFilterGraphProps {
  dateInfo: N<IDateInfo>;
  activeDetector: U<Detector>;
  isConfirm: boolean;
  graphTitle: RadioValue;
  filterDtData: FilterDataValues;
}

const APP_PATH =
  'components.trafficLightDetailed.tlDetectors.IntancyGraph.hooks.useFilterGraph';

const useFilterGraph = ({
  dateInfo,
  activeDetector,
  isConfirm,
  graphTitle,
  filterDtData,
}: UseFilterGraphProps) => {
  const [filterInfo, setFilterInfo] = useState<FilterInfo[]>([]);

  const isModalOpen = useMemo(() => {
    const value = dateInfo?.radioValueDate;

    return Boolean(
      dateInfo &&
        isLineChartPeriod(value) &&
        DATES[value].value === SELECT_TIME.value &&
        !isConfirm
    );
  }, [dateInfo, isConfirm]);

  const {
    data: dataRes,
    isError,
    isFetching,
    refetch,
  } = useDetectorsData({
    appPath: APP_PATH,
    ids: activeDetector?.id ? [activeDetector.id] : undefined,
    interval: dateInfo?.interval,
    selectedDates: dateInfo?.selectedDate,
    timeZone: getTimeZones().timeZoneRegion,
    isNeedUtcFormatted: dateInfo?.isNeedUtcFormatted,
    directions: filterDtData.directions ?? undefined,
    lanes: filterDtData.lanes ?? undefined,
    graphTitle,
    vehicleTypes: filterDtData.vehicleTypes ?? undefined,
    isNeedTransform: true,
    enabled: Boolean(
      !isModalOpen &&
        activeDetector?.id &&
        dateInfo?.selectedDate &&
        filterDtData.directions
    ),
    specialKeys: [graphTitle],
  });

  const dataGraph = useMemo(
    () => (dataRes ? { title: dataRes.title, data: dataRes.data } : null),
    [dataRes]
  );

  // set filters buttons
  useEffect(() => {
    if (!activeDetector) return;

    setFilterInfo(getFilteredInfo(activeDetector));
  }, [activeDetector]);

  return {
    filterInfo,
    dataGraph,
    colors: dataRes?.colors,
    isError,
    isLoading: isFetching,
    handleUpdateGraph: refetch,
  };
};

export default useFilterGraph;
