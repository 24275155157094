export const ROUTE_CONTENT = {
  content: 'Расчёт маршрутов',
  settings: {
    directionHint:
      'Внимание! Указанные расстояния и длительность движения приведены ' +
      "для условий движения на ТС типа 'легковой автомобиль' с ограничением " +
      'скорости, установленном для соответствующих участков УДС ' +
      'и без учёта пробок и остановок на светофорах и перекрёстках',
  },
};

export const START_POINT_ICON_SIZE = 12;

export const START_POINT_STYLE = { marginLeft: -10, padding: 4 };
