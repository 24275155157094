import { useQuery } from '@tanstack/react-query';
import { isNumber } from 'lodash';

import { ChartBDKeys } from '../../../../components/InfoPanel/SystemsInfo/PopupDetector/Chart/constants/constants';
import { Y_STATISTIC_KEY } from '../../../../components/ui-kit/Chart/CandleChart/constants/constants';
import getPreparedGraphStructure from '../../../../components/ui-kit/Chart/CandleChart/helpers/getPreparedGraphStructure';
import { getCalcData } from '../../../../components/ui-kit/Chart/helpers/getCalcData';
import {
  QUERY_KEY,
  UPDATE_PREDICT_CHART_INTERVAL,
} from '../../../../constants/queryConstants';
import {
  PredictDataReturn,
  UseDetectorsDataQueryProps,
  UseDtStatisticQueryReturn,
} from '../model/dtStatistics.model';

const { DETECTORS_PREDICT_DATA } = QUERY_KEY;

const useDtStatisticDataQuery = <T extends U<ChartBDKeys> = undefined>({
  fetchPredictData,
  appPath,
  enabled = true,
  specialKeys,
  id,
  left,
  right,
  directions,
  valueType,
  signal,
}: UseDetectorsDataQueryProps<T>): UseDtStatisticQueryReturn<T> => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      DETECTORS_PREDICT_DATA,
      id,
      left,
      right,
      directions,
      ...(specialKeys ?? []),
    ],
    queryFn: async () => {
      if (!directions || !isNumber(id)) return null;

      const dtData = await fetchPredictData({
        id,
        left,
        right,
        directions,
        signal,
        appPath,
      });

      if (valueType) {
        const { chartNames, formattedData } = getPreparedGraphStructure(
          dtData,
          valueType
        );

        const calcData = getCalcData({
          data: formattedData,
          keyX: Y_STATISTIC_KEY,
        });

        return { calcData, chartNames };
      }

      return dtData ?? null;
    },
    refetchInterval: UPDATE_PREDICT_CHART_INTERVAL,
    enabled,
  });

  return {
    data: data as PredictDataReturn<T>,
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

export default useDtStatisticDataQuery;
