import React, { useEffect, useState } from 'react';

import { IDirectionResult } from '../../../../apiGIS/models/tasks/tasks.model';
import {
  clearDirections,
  drawDirections,
} from '../../../../apiGIS/tasks/direction';
import rootStore from '../../../../stores/rootStore/rootStore';
import { ROUTE_CONTENT } from '../constants';
import { getDirection } from '../helpers';
import { IRoutePoint, RoutePointEnum } from '../route.model';

import { RoutePoint } from './RoutePoint';

import styles from '../RouteButton.module.scss';

const DEFAULT_DIRECTION_RESULT: IDirectionResult = {
  coordinates: [],
  distance: 0,
  instructions: [],
  points: [],
  time: '',
};

const RoutePanel = () => {
  const { mapProxy } = rootStore.mapDataStore;

  const [point1, setPoint1] = useState<IRoutePoint>({
    type: RoutePointEnum.START_POINT,
  });
  const [point2, setPoint2] = useState<IRoutePoint>({
    type: RoutePointEnum.END_POINT,
  });
  const [direction, setDirection] = useState<IDirectionResult>(
    DEFAULT_DIRECTION_RESULT
  );

  useEffect(() => {
    getDirection(point1, point2).then((result) => {
      result && setDirection(result);
    });
  }, [point1, point2]);

  useEffect(() => {
    clearDirections(mapProxy);
    drawDirections(mapProxy, [direction]);
  }, [direction, mapProxy]);

  return (
    <div className={styles.searchContainer}>
      <RoutePoint value={point1} map={mapProxy} onChange={setPoint1} />
      <RoutePoint value={point2} map={mapProxy} onChange={setPoint2} />

      {direction && direction.instructions.length > 0 ? (
        <>
          {direction.time && direction.distance ? (
            <div className={styles.directionSummaryContainer}>
              <div className={styles.directionSummaryResultItem}>
                Расстояние: <b>{direction.distance}км</b>
              </div>
              <div className={styles.directionSummaryResultItem}>
                Длительность: <b>{direction.time}</b>
              </div>
              <div className={styles.settingsHint}>
                {ROUTE_CONTENT.settings.directionHint}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default RoutePanel;
