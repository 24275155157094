import { Map } from 'ol';

import { DRAPING_LAYER_ID, Z_INDEX_LAYER } from '../../../apiGIS/constants/map';
import { FeatureLayer } from '../../../apiGIS/layers/FeatureLayer';
import { createDrapingLayer } from '../../../apiGIS/utils/draping';
import { ILayer } from '../../../stores/gisDataStore/gisDataStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { VisibleLayer } from '../Map.model';

export const setVisibleLayers = (map: Map, definitions: ILayer[]) => {
  createDrapingLayer(map, definitions);

  return definitions.reduce((results: VisibleLayer[], layerDefinition) => {
    const { setGisValue } = rootStore.gisDataStore;

    const featureLayer = new FeatureLayer({
      layerDefinition,
      map,
    });

    const layer = featureLayer.getInstance();

    if (!layer) return results;

    const { id, visible, group } = layerDefinition;

    if (id === DRAPING_LAYER_ID) {
      return results;
    }

    map.addLayer(layer);

    layer.setVisible(visible);
    layer.setZIndex(Z_INDEX_LAYER);

    layer.on('change:visible', (evt) => {
      const { layersVisibilityProxy } = rootStore.gisDataStore;

      const state = layersVisibilityProxy
        ? JSON.parse(layersVisibilityProxy)
        : {};

      state[id] = !evt.oldValue;

      setGisValue('layersVisibility', JSON.stringify(state));
    });

    results.push({ id, visible, group });

    return results;
  }, []);
};

export const removeVisibleLayers = (
  map: Map,
  visibleLayers: VisibleLayer[]
) => {
  const layers = map.getAllLayers();
  const visibleLayersIds = visibleLayers.map(({ id }) => id);

  layers.forEach((layer) => {
    const id = layer.get('id');

    visibleLayersIds.includes(id) && map.removeLayer(layer);
  });
};
