import { flow, makeAutoObservable, toJS } from 'mobx';
import { toLonLat } from 'ol/proj';

import { Path } from '../../api/api.model';
import pointUdsApi from '../../api/pointUds/pointUdsApi';
import profilesApi from '../../api/pointUds/profiles/profilesApi';
import {
  isCamera,
  isMeteo,
  isTrafficLane,
} from '../../components/Constructor/BlockConstructor/BlockConstructor.model';
import { ICON_SIZE } from '../../components/Constructor/constants/constants';
import { FOLDER_PATHS } from '../../components/Constructor/DetailedConstructor/ConstructorPhaseImages/constants/constants';
import getPhaseImagesFolder from '../../components/Constructor/DetailedConstructor/ConstructorPhaseImages/helpers/getPhaseImagesFolder';
import { LaneDirectionKey } from '../../components/Constructor/TrafficLaneConstructor/TLaneConstructor.model';
import { OffsetKey } from '../../components/Constructor/UIKitConstructor/Offset/Offset.model';
import { handleZoom } from '../../components/Map/helpers/zoomHandlers/handleZoom';
import notification from '../../components/ui-kit/Notification/Notification';
import {
  DEFAULT_PHASE_IMG_PATH,
  TL_CIRCLE_ZOOM,
} from '../../constants/constants';
import {
  CAMERA_DEFAULT,
  METEO_DEFAULT,
  CIRCLE_ELEMENT_DYNAMIC,
  CIRCLE_ELEMENT_OBJ,
  CROSSROAD_DEFAULT,
  DEFAULT_CIRCLE,
  DEFAULT_CIRCLE_ELS_DYNAMIC,
  LANE_SPAN,
  PHASES_DEFAULT,
  TL_DEFAULT,
  TRAFFIC_LANE_DEFAULT,
  VIEW_DEFAULT,
  IS_THE_SAME_PROJECTION_COLOR,
} from '../../constants/constructorConstants';
import { findBy, findById } from '../../helpers/findBy';
import { zooms } from '../../helpers/findZoomRangeStart';
import sortById from '../../helpers/sortById';
import { ViewStatus } from '../../ts/enums/constructor';
import { RightPanelType } from '../../ts/enums/enums';
import { TLPhaseCodes } from '../../ts/enums/tl';
import {
  ICameraConstructor,
  ICircleElConstructor,
  ICrossroad,
  Id,
  PointUds,
  ProfileHistory,
  ProfilesList,
  TrafficLane,
  ViewKeys,
  LonLat,
  Meteo,
  Selected,
} from '../../ts/models/constructor.model';
import getLaneWidth from '../helpers/getLaneWidth';
import RootStore from '../rootStore/rootStore';

import {
  ConstructorArrays,
  ICircleElsGeneral,
  IPhase,
  SetKeyValue,
  SetCamera,
  SetCircleEl,
  SetCircleElDynamic,
  SetTrafficLane,
  TConstructorBools,
  ConstructorTL,
  ChangeDevice,
  SetMeteo,
  DeleteById,
  SetLaneDirection,
  SetLaneParams,
} from './constructorStore.model';
import getLaneDirection from './helpers/getLaneDirection';

const { DIAMETER, BORDER_WIDTH, OPACITY, HUE, DIAMETER_RATE } = DEFAULT_CIRCLE;

const { ToCamera } = LaneDirectionKey;
const { X, Y } = OffsetKey;

class ConstructorStore {
  rootStore;
  view = VIEW_DEFAULT;
  isConstructorLoaded = false;
  isCircleConstructor = false;
  viewStatus = ViewStatus.Disabled;
  isBlinkingAnimation = false;
  isApplyCenter = false;
  isCircleCenter = true;
  isFinalVersion = false;
  isAppliedAsFinal = false;
  isCenteredByClick = true;
  isAutoRange = false;
  circleCenter: XY = [0, 0];
  circleCenterPixels: XY = [0, 0];
  circleDiameter = DIAMETER;
  circleDiameterRate = DIAMETER_RATE;
  circleBorderWidth = BORDER_WIDTH;
  circleOpacity = OPACITY;
  circleHue = HUE;
  zoomRangeEnd = TL_CIRCLE_ZOOM.END;
  importedZoom = null;
  isWheelZoom = true;
  circleElsGeneral: ICircleElsGeneral[] = [];
  circleElsDynamic = DEFAULT_CIRCLE_ELS_DYNAMIC;
  phases: IPhase[] = PHASES_DEFAULT;
  isCrossroadLoaded = false;
  crossroad: ICrossroad = CROSSROAD_DEFAULT;
  cameras: ICameraConstructor[] = [];
  trafficLanes: TrafficLane[] = [];
  meteos: Meteo[] = [];
  isCameraIconId = false;
  isDetectorIconId = false;
  zoomRangeStart = TL_CIRCLE_ZOOM.START;
  zoomRangePoint = TL_CIRCLE_ZOOM.START;
  currentPhaseIdx = 1;
  profileId: N<number> = null;
  pointUdsUid: N<string> = null;
  tl: ConstructorTL = TL_DEFAULT;
  create: Nullish<Date> = null;
  pointUdsList: U<PointUds[]>;
  profilesList: U<ProfilesList[]>;
  profileHistory: ProfileHistory[] = [];
  isImportedProfile = false;
  activeAccordionId: N<string> = null;
  isTheSameProjectionColor = IS_THE_SAME_PROJECTION_COLOR;
  isNewPhaseImages = false;
  videoDtIconSize = ICON_SIZE.DEFAULT;
  cmIconSize = ICON_SIZE.DEFAULT;
  isVideoDtIcons = true;
  selectedProfile: Selected = null;
  isProfileLoading = false;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      fetchPointUdsList: flow.bound,
      fetchProfilesList: flow.bound,
    });
    this.rootStore = rootStore;
  }

  setIsNotConstructor = (key: TConstructorBools, bool?: boolean) => {
    this[key] = bool ?? !this[key];
  };

  setConstructorData: SetKeyValue<this> = (key, value) => (this[key] = value);

  setConstructorKeysValues = <K extends keyof this>(params: {
    [key in K]: this[key];
  }) => {
    for (const key in params) {
      this[key] = params[key];
    }
  };

  setView = (key: ViewKeys, bool?: boolean) => {
    this.view[key] = bool ?? !this.view[key];
  };

  setCrossroad: SetKeyValue<ICrossroad> = (key, value) => {
    const { setTrafficLane, trafficLanes, crossroad, cameras, setCamera } =
      this;
    const { isTrafficLanesOffset, isCamerasOffset, offsetX, offsetY } =
      crossroad;

    if (typeof value === 'number') {
      if (isTrafficLanesOffset) {
        trafficLanes.forEach((trafficLane) => {
          const { id } = trafficLane;
          const newOffsetX = trafficLane.offsetX + value - offsetX;
          const newOffsetY = trafficLane.offsetY + value - offsetY;

          key === 'offsetX' && setTrafficLane('offsetX', newOffsetX, id);
          key === 'offsetY' && setTrafficLane('offsetY', newOffsetY, id);
        });
      }

      if (isCamerasOffset) {
        cameras.forEach((camera) => {
          const { id } = camera;

          if (id === null) return;

          const newOffsetX = camera.offsetX + value - offsetX;
          const newOffsetY = camera.offsetY + value - offsetY;

          key === 'offsetX' && setCamera('offsetX', newOffsetX, id);
          key === 'offsetY' && setCamera('offsetY', newOffsetY, id);
        });
      }
    }

    crossroad[key] = value;
  };

  setCrossroadPhase = (phase: number, checked: boolean) => {
    const phases = toJS(this.crossroad.phases);
    const idx = phases.findIndex((el) => el.phase === phase);

    if (idx === undefined) return;

    this.crossroad.phases = [
      ...phases.slice(0, idx),
      {
        phase,
        checked,
      },
      ...phases.slice(idx + 1),
    ];
  };

  setTrafficLane: SetTrafficLane = (key, value, id) => {
    const { trafficLanes, setLonLat } = this;
    const trafficLane = findById(trafficLanes, id);

    if (!trafficLane) return;
    trafficLane[key] = value;

    if (key !== 'dtIconOffsetX' && key !== 'dtIconOffsetY') return;
    const offsetKey = `o${key.replace(/dtIconO/g, '')}` as OffsetKey;

    setLonLat(offsetKey, Number(value), trafficLane);
  };

  setLaneDirection: SetLaneDirection = (key, value, id, params) => {
    const directionKey = params?.directionKey ?? ToCamera;
    const { direction } = getLaneDirection(id, directionKey);

    if (!direction) return;
    direction[key] = value;
  };

  addTrafficLane: ChangeDevice<TrafficLane> = ({ id, caption, data }) => {
    let tLane = { ...TRAFFIC_LANE_DEFAULT, id, caption };

    if (data && isTrafficLane(data)) {
      tLane = data;
    }

    this.trafficLanes.push(tLane);
  };

  setLaneValue: SetLaneParams = ({
    key,
    value,
    id,
    laneId,
    directionKey = ToCamera,
  }) => {
    const { direction } = getLaneDirection(id, directionKey);

    if (!direction) return;
    const { laneParams } = direction;
    const laneParamsItem = findById(laneParams, laneId);

    if (laneParamsItem) {
      laneParamsItem[key] = value;
    }
  };

  setLanesAmount = (amount: number, id: Id, directionKey = ToCamera) => {
    const { direction } = getLaneDirection(id, directionKey);

    if (!direction) return;
    const { laneParams } = direction;

    if (laneParams.length >= amount) {
      const newLaneParams = [...laneParams].slice(0, amount);

      direction.width = getLaneWidth(newLaneParams);
      direction.laneParams = newLaneParams;

      return;
    }

    direction.laneParams.push({
      id: (laneParams.at(-1)?.id ?? 0) + 1,
      span: LANE_SPAN,
      width: laneParams.at(-1)?.width ?? 0,
      offset: getLaneWidth(laneParams) + LANE_SPAN,
      number: null,
      zoneUID: null,
    });

    direction.width = getLaneWidth(laneParams);
  };

  setLaneWidth = (
    value: number,
    id: Id,
    laneId: Id,
    directionKey = ToCamera
  ) => {
    const { direction } = getLaneDirection(id, directionKey);

    if (!direction) return;
    const { laneParams } = direction;

    const laneParamsItem = findById(laneParams, laneId);

    if (laneParamsItem) laneParamsItem.width = value;

    let offset = direction.laneParams[0].offset;

    direction.laneParams = [...direction.laneParams].map((params) => {
      const newParams = {
        ...params,
        offset,
      };

      offset += params.width + LANE_SPAN;

      return newParams;
    });

    direction.width = getLaneWidth(direction.laneParams);
  };

  changeTrafficLane: ChangeDevice<TrafficLane> = ({ id, data }) => {
    if (!data) return;

    const { trafficLanes } = this;
    const idx = trafficLanes.findIndex((el) => el.id === id);

    if (idx < 0) return;
    this.trafficLanes = [
      ...trafficLanes.slice(0, idx),
      data,
      ...trafficLanes.slice(idx + 1),
    ];
  };

  getLonLat = (coordinate: XY) => {
    const { map } = this.rootStore.mapStore;

    return map
      ? toLonLat(map.getCoordinateFromPixel(coordinate))
      : [undefined, undefined];
  };

  setLonLat = (key: OffsetKey, value: number, obj: LonLat) => {
    const { calcCircleVal } = this.rootStore.pointsUdsStore;
    const { circleCenterPixels, getLonLat } = this;
    const [pX, pY] = circleCenterPixels;
    const offset = calcCircleVal(value);

    if (key === X) {
      const pixelX = pX + offset;
      const latitude = getLonLat([pixelX, pY])[1];

      obj.latitude = latitude;
    }

    if (key === Y) {
      const pixelY = pY + offset;
      const longitude = getLonLat([pX, pixelY])[0];

      obj.longitude = longitude;
    }
  };

  setCamera: SetCamera = (key, value, id) => {
    const { cameras, setLonLat } = this;
    const camera = cameras.find((el) => el.id === id);

    if (!camera) return;
    camera[key] = value;

    if (key !== X && key !== Y) return;
    setLonLat(key as OffsetKey, Number(value), camera);
  };

  addCamera: ChangeDevice<ICameraConstructor> = ({ id, caption, data }) => {
    const { circleCenter } = this;

    let camera: ICameraConstructor = {
      ...CAMERA_DEFAULT,
      id,
      caption,
      longitude: circleCenter[0],
      latitude: circleCenter[1],
    };

    if (data && isCamera(data)) {
      camera = data;
    }

    this.cameras.push(camera);
  };

  changeCamera: ChangeDevice<ICameraConstructor> = ({ id, data }) => {
    if (!data || !isCamera(data)) return;

    const { cameras } = this;
    const idx = cameras.findIndex((el) => el.id === id);

    if (idx < 0) return;
    this.cameras = [...cameras.slice(0, idx), data, ...cameras.slice(idx + 1)];
  };

  setMeteo: SetMeteo = (key, value, id) => {
    const { meteos, setLonLat } = this;
    const meteo = meteos.find((el) => el.id === id);

    if (!meteo) return;
    meteo[key] = value;

    if (key !== X && key !== Y) return;
    setLonLat(key as OffsetKey, Number(value), meteo);
  };

  addMeteo: ChangeDevice<Meteo> = ({ id, caption, data }) => {
    const { circleCenter } = this;

    const meteo =
      data && isMeteo(data)
        ? data
        : ({
            ...METEO_DEFAULT,
            id,
            caption,
            longitude: circleCenter[0],
            latitude: circleCenter[1],
          } as Meteo);

    this.meteos.push(meteo);
  };

  get sortedCameras() {
    return sortById(this.cameras);
  }

  get sortedTLanes() {
    return sortById(this.trafficLanes);
  }

  deleteFromArray = (key: ConstructorArrays, idx: number) =>
    this[key].splice(idx, 1);

  deleteById: DeleteById = (key, id) => {
    const idx = this[key].findIndex((el) => el.id === id);

    this.deleteFromArray(key, idx);
  };

  setTL: SetKeyValue<ConstructorTL> = (key, value) => (this.tl[key] = value);

  setCircleEl: SetCircleEl = (idx, key, value) => {
    const newEl = { ...this.circleElsGeneral[idx], [key]: value };

    this.circleElsGeneral[idx] = newEl;
  };

  setCircleElFull: SetCircleEl = (idx, key, value) => {
    const { circleElsGeneral } = this;

    circleElsGeneral[idx][key] = value;
    this.circleElsGeneral = [...circleElsGeneral];
  };

  setCircleElDynamic: SetCircleElDynamic = (key, value, idx, byHand?) => {
    const { circleElsDynamic, zoomRangePoint, isAutoRange } = this;

    if (!circleElsDynamic[zoomRangePoint]) {
      const closestZoomRange = zooms.find((zoom) => {
        return zoom <= zoomRangePoint && circleElsDynamic[zoom];
      });

      if (closestZoomRange) {
        circleElsDynamic[zoomRangePoint] = JSON.parse(
          JSON.stringify(toJS(circleElsDynamic[closestZoomRange]))
        );
      }
    }

    if (!byHand) {
      circleElsDynamic[zoomRangePoint][idx][key] = value;
    }

    if (isAutoRange || byHand) {
      this.zoomRangeStart = zoomRangePoint;
    }
  };

  addCircleEl = () => {
    const { circleElsDynamic, circleElsGeneral, directions } = this;

    const directionNumber = directions.length
      ? Math.max(...this.directions) + 1
      : 1;

    circleElsGeneral.push({
      ...CIRCLE_ELEMENT_OBJ,
      direction: directionNumber,
    });

    for (const key in circleElsDynamic) {
      circleElsDynamic[key].push(CIRCLE_ELEMENT_DYNAMIC);
    }
  };

  deleteCircleEl = (idx: number) => {
    const {
      phases,
      deleteFromArray,
      zoomRangePoint,
      circleElsDynamic,
      circleElsGeneral,
    } = this;
    const { direction } = circleElsGeneral[idx];

    phases.forEach((ph) => {
      const directionIdx = ph.directions.findIndex(
        (item) => item === direction
      );

      directionIdx >= 0 && ph.directions.splice(directionIdx, 1);
    });

    deleteFromArray('circleElsGeneral', idx);
    circleElsDynamic[zoomRangePoint] &&
      circleElsDynamic[zoomRangePoint].splice(idx, 1);
  };

  deleteZoomRange = (range: string) => {
    const { circleElsDynamicKeys, circleElsDynamic } = this;
    const rangeOffset = Number(range) - TL_CIRCLE_ZOOM.STEP;

    const newZoomRangeStart = circleElsDynamicKeys
      .reverse()
      .find((key) => Number(key) <= rangeOffset);

    handleZoom(Number(newZoomRangeStart));
    this.zoomRangeStart = Number(newZoomRangeStart);
    delete circleElsDynamic[Number(range)];
  };

  onPhase = (idx: number) => {
    const { phases, circleElsGeneral } = this;

    this.currentPhaseIdx = idx;
    circleElsGeneral.forEach((el) => (el.isCircleElement = false));

    phases[idx].directions.forEach((direction) => {
      const elIdx = circleElsGeneral.findIndex(
        (el) => el.direction === direction
      );

      if (elIdx >= 0) {
        circleElsGeneral[elIdx].isCircleElement = true;
      }
    });
  };

  setPhaseImagePath = (i: number, path = DEFAULT_PHASE_IMG_PATH) => {
    this.phases[i].image = path;
    this.phases[i].cache = Math.random();
  };

  setPhase = (
    folder: string,
    name: string,
    i: number,
    isChangeImage = true
  ) => {
    if (isChangeImage) {
      this.phases[i].image = FOLDER_PATHS.DEFAULT + folder + name;
    }
    this.phases[i].name = name;
  };

  addPhase = (phaseNumber: N<number>) => {
    const {
      phases,
      onPhase,
      isNewPhaseImages,
      tl: { id },
    } = this;
    const cache = Math.random();
    const folder = getPhaseImagesFolder().path;

    const image = isNewPhaseImages
      ? `${folder + id}_${phaseNumber}.png`
      : `${folder}Sg${id}_${phaseNumber}.png`;

    const newPhase = {
      phaseNumber: phaseNumber,
      directions: [],
      code: phaseNumber ? TLPhaseCodes.Phase : TLPhaseCodes.Undefined,
      image,
      cache,
    };

    phases.push(newPhase);
    onPhase(phases.length - 1);
  };

  handlePhase = (circleElIdx: number) => {
    const { circleElsGeneral, phases, currentPhaseIdx } = this;
    const ph = phases[currentPhaseIdx];

    !ph && notification.error('CONSTRUCTOR_PHASE_ERROR');
    if (!phases.length || !ph) return;

    const { isCircleElement, direction } = circleElsGeneral[circleElIdx];
    const { directions } = ph;

    if (!isCircleElement) {
      const idx = directions.findIndex((el) => el === direction);

      return idx >= 0 && directions.splice(idx, 1);
    }

    !directions.includes(direction) && directions.push(direction);
  };

  *fetchPointUdsList(path: Path) {
    const data: PointUds[] = yield pointUdsApi.fetchPointUdsList(path);

    this.setConstructorData('pointUdsList', data);
  }

  *fetchProfilesList(path: Path) {
    const { pointUdsUid, setConstructorData } = this;

    if (!pointUdsUid) return;

    const profilesList: U<ProfilesList[]> = yield profilesApi.fetchProfiles(
      pointUdsUid,
      path
    );

    setConstructorData('profilesList', profilesList);
  }

  linkCameraDetector = (detectorId: N<number>, cameraId: number) => {
    const { trafficLanes, setCamera, setTrafficLane, cameras } = this;
    const lane = findBy(trafficLanes, cameraId, 'linkedCameraId');
    const camera = findById(cameras, cameraId);

    if (lane) {
      setTrafficLane('linkedCameraId', null, lane.id);
      !detectorId && setTrafficLane('linkColor', null, lane.id);
    }

    if (detectorId && camera) {
      const { offsetX, offsetY, linkColor } = camera;

      setTrafficLane('dtIconOffsetX', offsetX, detectorId);
      setTrafficLane('dtIconOffsetY', offsetY, detectorId);
      setTrafficLane('linkedCameraId', cameraId, detectorId);
      setTrafficLane('linkColor', linkColor, detectorId);
    }

    setCamera('linkedDetectorId', detectorId, cameraId);
  };

  // getters
  get isConstructor() {
    const { isPanel, panelType } = this.rootStore.uiStore;

    return isPanel && panelType === 'constructor' && this.isCircleConstructor;
  }

  get circleParams() {
    return {
      diameter: this.circleDiameter,
      opacity: this.circleOpacity,
      hue: this.circleHue,
      borderWidth: this.circleBorderWidth,
      animation: this.isBlinkingAnimation ? [{ type: 'switch' }] : null,
    };
  }

  get circleEls(): ICircleElConstructor[] {
    const { circleElsGeneral, circleElsDynamic, zoomRangeStart } = this;

    if (!circleElsGeneral.length || !circleElsDynamic[TL_CIRCLE_ZOOM.START])
      return [];

    return circleElsGeneral.map((circleEl, i) => {
      return {
        ...circleEl,
        ...(circleElsDynamic[zoomRangeStart][i] ?? {}),
        idx: i,
      };
    });
  }

  get circleElsDynamicKeys() {
    return Object.keys(this.circleElsDynamic).sort();
  }

  get directions() {
    return this.circleElsGeneral.map((el) => el.direction);
  }

  get phaseNumbers() {
    return this.phases.reduce<number[]>((res, el) => {
      if (el.phaseNumber) res.push(el.phaseNumber);

      return res;
    }, []);
  }

  get isConstructorDetailed() {
    const { rightPanelType } = this.rootStore.uiStore;

    return rightPanelType === RightPanelType.Constructor;
  }

  get isAllCamerasExtended() {
    return this.cameras.every((cam) => cam.isExtended);
  }

  get isLinkedStatus() {
    return this.viewStatus === ViewStatus.Linked;
  }

  clearTLData = () => {
    this.circleElsGeneral = [];
    this.circleElsDynamic = DEFAULT_CIRCLE_ELS_DYNAMIC;
    this.phases = PHASES_DEFAULT;
    this.tl = TL_DEFAULT;
    this.circleOpacity = OPACITY;
    this.isBlinkingAnimation = false;
  };

  clearStore = (isExit = true) => {
    const { setRightPanel } = this.rootStore.uiStore;

    if (isExit) {
      this.isCircleConstructor = false;
      this.pointUdsList = undefined;
    }

    this.view = VIEW_DEFAULT;
    this.circleCenter = [0, 0];
    setRightPanel(null);
    this.isCenteredByClick = true;
    this.crossroad = CROSSROAD_DEFAULT;
    this.zoomRangeStart = TL_CIRCLE_ZOOM.START;
    this.cameras = [];
    this.trafficLanes = [];
    this.create = null;
    this.pointUdsUid = null;
    this.profileId = null;
    this.clearTLData();
    this.isImportedProfile = false;
    this.tl = TL_DEFAULT;
    this.meteos = [];
    this.isTheSameProjectionColor = IS_THE_SAME_PROJECTION_COLOR;
    this.isNewPhaseImages = false;
    this.selectedProfile = null;
  };
}

export default ConstructorStore;
