/* eslint-disable camelcase */
import { isNumber } from 'lodash';

import { findBy } from '../../../../helpers/findBy';
import sortByStr from '../../../../helpers/sortByStr';
import rootStore from '../../../../stores/rootStore/rootStore';
import {
  DataLanesDetectors,
  DetectorsDataGraph,
  DetectorsDataInfo,
  DirectionsTypes,
  VehicleTypes,
} from '../../../InfoPanel/SystemsInfo/PopupDetector/PopupDetector.model';
import { FilterDataValues } from '../../../ui-kit/Chart/models/chart.model';
import {
  COLORS_GRAPH_DT,
  COLORS_VEHICLES_DT,
  DIRECTIONS_ICONS,
  VEHICLES_ICONS,
} from '../constants/constants';
import { RadioValue } from '../model/graph.model';

interface Types {
  TYPE: number;
}

type DtData = DetectorsDataGraph['data'];
type InfoKeys = keyof DtData[number]['info'][number];

const isTypesFilter = (
  dataGraph: DtData,
  keyType: InfoKeys,
  filterDtData: FilterDataValues
): dataGraph is DataLanesDetectors[] => {
  const infoDt = dataGraph[0].info;

  const isType = infoDt.every((el) => el[keyType] !== undefined);

  if (keyType === 'db3_lane') return isType && !!filterDtData.lanes;

  if (keyType === 'lb_vehicle_type_id')
    return isType && !!filterDtData.vehicleTypes;

  return false;
};

const getFilterInfo = <T extends Types, K extends keyof T>(
  infoDt: DetectorsDataInfo[],
  dirNames: DetectorsDataGraph['directionsNames'],
  data: T[],
  findKey: K
) =>
  infoDt.map(({ lb_direction_id }, i) => {
    const dictionaryVal = dirNames.find(
      ({ direction_id }) => direction_id === lb_direction_id
    );

    const dirName = getDirName(dirNames, lb_direction_id, i);
    const dirType = dictionaryVal?.direction_id ?? DirectionsTypes.Undefined;
    const directionData = findBy(data, dirType, 'TYPE');
    const value = directionData ? directionData[findKey] : data[0][findKey];

    return { name: dirName, value, type: dirType };
  });

const getVehiclesColors = (infoDt: DetectorsDataInfo[]) =>
  infoDt.map(({ lb_vehicle_type_id }, i) => {
    const dirType = lb_vehicle_type_id ?? VehicleTypes.Unknown;
    const name =
      findBy(VEHICLES_ICONS, dirType, 'type')?.name ?? 'Не определен';
    const value = findBy(COLORS_VEHICLES_DT, dirType, 'TYPE')?.COLOR;

    return { name, value, type: dirType };
  });

const getLanesData = (
  dataGraph: DataLanesDetectors[],
  mainDataKey: RadioValue
) => {
  return dataGraph.map(({ caption, info }) => {
    const graphPoint = info.reduce(
      (acc: OWKS<SN>, el) => {
        const laneName = `Полоса № ${el.db3_lane}`;
        const value: U<SN> = acc[laneName];
        const lanesValue = Math.trunc(el[mainDataKey]);

        acc[laneName] = isNumber(value) ? value + lanesValue : lanesValue;

        return acc;
      },
      { time: caption }
    );

    return graphPoint;
  });
};

const getVehiclesData = (
  dataGraph: DataLanesDetectors[],
  mainDataKey: RadioValue
) => {
  return dataGraph.map(({ caption, info }) => {
    const graphPoint = info.reduce(
      (acc: OWKS<SN>, el) => {
        const vehicleName =
          findBy(VEHICLES_ICONS, el.lb_vehicle_type_id, 'type')?.name ??
          'Не определен';
        const value: U<SN> = acc[vehicleName];
        const lanesValue = Math.trunc(el[mainDataKey]);

        acc[vehicleName] = isNumber(value) ? value + lanesValue : lanesValue;

        return acc;
      },
      { time: caption }
    );

    return graphPoint;
  });
};

const getDirName = (
  directionsNames: DetectorsDataGraph['directionsNames'],
  dirId: DirectionsTypes,
  i: number
) => {
  const dictName =
    directionsNames.find(({ direction_id }) => direction_id === dirId)
      ?.direction_hl ?? `Направление ${i + 1}`;

  const dictValue =
    DIRECTIONS_ICONS.find(({ name }) => dictName.includes(name))?.name ??
    dictName;

  return dictValue.toLowerCase();
};

const getTitleGraph = (
  directionsNames: N<DetectorsDataGraph['directionsNames']>
) => {
  const dirName = directionsNames?.at(0)?.direction_hl;

  const dirVal = DIRECTIONS_ICONS?.find(({ name }) =>
    dirName?.includes(name)
  )?.name;

  const titleGraph = dirVal && dirName?.split(dirVal)?.at(0);

  return titleGraph ?? '';
};

const getColorsArray = (
  infoDt: DetectorsDataInfo[],
  directionsNames: DetectorsDataGraph['directionsNames'],
  isLaneFilter: boolean,
  isVehicleFilter: boolean
) => {
  if (isVehicleFilter) return getVehiclesColors(infoDt);

  if (isLaneFilter) return undefined;

  return getFilterInfo(infoDt, directionsNames, COLORS_GRAPH_DT, 'COLOR');
};

export const normilizeData = (
  mainDataKey: RadioValue,
  dataRes: DetectorsDataGraph,
  isMinificGraph = false
) => {
  const { filterDtData } = rootStore.detailedStore;
  const dataGraph = sortByStr(dataRes.data, 'date_to');

  const directionsNames = dataRes.directionsNames;

  const title = getTitleGraph(directionsNames);

  if (!dataGraph?.length || !directionsNames?.length)
    return { data: [], loading: false, colors: undefined, title };

  const infoDt = dataGraph[0].info;

  const isLaneFilter = isTypesFilter(dataGraph, 'db3_lane', filterDtData);
  const isVehicleFilter = isTypesFilter(
    dataGraph,
    'lb_vehicle_type_id',
    filterDtData
  );

  const colors = getColorsArray(
    infoDt,
    directionsNames,
    isLaneFilter,
    isVehicleFilter
  );

  const resDefault = { loading: false, colors, title };

  if (isVehicleFilter)
    return { ...resDefault, data: getVehiclesData(dataGraph, mainDataKey) };

  if (isLaneFilter)
    return { ...resDefault, data: getLanesData(dataGraph, mainDataKey) };

  const data = dataGraph.map(({ date_from, date_to, caption, info }) => {
    const graphPoint = info.reduce(
      (acc: OWKS<SN>, el, i) => {
        const dictionaryLowerVal = getDirName(
          directionsNames,
          el.lb_direction_id,
          i
        );

        acc[dictionaryLowerVal] = Math.trunc(el[mainDataKey]);

        return acc;
      },
      { time: caption }
    );

    const res = isMinificGraph
      ? { ...graphPoint, dateTo: date_to, dateFrom: date_from }
      : { ...graphPoint };

    return res;
  });

  return { ...resDefault, data };
};
